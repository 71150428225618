import { IsPc } from "./utils/index";

const HomePc = () => import("./pages/pc/index/index.vue");
const HomeMobile = () => import("./pages/mobile/index/index.vue");
const StartPc = () => import("./pages/pc/start/index.vue");
const StartMobile = () => import("./pages/mobile/start/index.vue");
const AboutPc = () => import("./pages/pc/about/index.vue");
const AboutMobile = () => import("./pages/mobile/about/index.vue");
const PolicyPc = () => import("./pages/pc/policy/index.vue");
const PolicyMobile = () => import("./pages/mobile/policy/index.vue");

let home = HomeMobile;
let start = StartMobile;
let about = AboutMobile;
let policy = PolicyMobile;

if (IsPc()) {
	home = HomePc;
	start = StartPc;
	about = AboutPc;
	policy = PolicyPc
}


const routes = [
	{
		path: "/",
		name: "index",
		component: home
	},
	{
		path: "/start",
		name: "start",
		component: start
	},
	{
		path: "/about",
		name: "about",
		component: about
	},
	{
		path: "/policy",
		name: "policy",
		component: policy
	}
];

export default routes;
