import zhMessages from './zh'
import enMessages from './en'
const messages = {
  en: {
    message: enMessages
  },
  zh: {
    message: zhMessages
  }
}

export default messages