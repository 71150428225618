<template>
  <div id="app">
    <Header></Header>
    <router-view class="main"></router-view>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";

export default {
  name: "App",
  data() {
    return {};
  },
  components: {
    Header,
  },
};
</script>

<style>
.main {
    min-height: calc(100vh - 190px);
}
</style>
