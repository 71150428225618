<template>
  <div class="header_box">
    <div class="header">
      <router-link  class="header_a" :to="{ name: 'index',query:{id: $route.query.id}}" @click.native="handleGaclick('home', 'logo click')">
        <img
          src="https://gpp142414-prod.s3.us-east-2.amazonaws.com/public/assets/logo.svg"
          alt="logo"
          class="logo-img"
        />
      </router-link >
      <div class="nav">
        <div class="nav_list" :class="{ cur_nav_list: $route.name == 'index' }" @click="handleGaclick('home')">
          <a href="#start" v-if="$route.name == 'index'">{{ $t("message.header.home") }}</a>
          <router-link v-else :to="{ name: 'index',query:{id: $route.query.id}}">{{ $t("message.header.home") }}</router-link>
        </div>
        <div class="nav_list" :class="{ cur_nav_list: $route.name == 'start' }" @click="handleGaclick('start')">
          <a href="#start" v-if="$route.name == 'index'">{{ $t("message.header.start") }}</a>
          <router-link v-else :to="{ name: 'start',query:{id: $route.query.id}}">{{ $t("message.header.start") }}</router-link>
        </div>
        <div
          class="nav_list"
          :class="{ cur_nav_list: $route.name == 'about' || $route.name == 'policy' }"
          @click="handleGaclick('help')"
        >
          <a href="#Help" v-if="$route.name == 'index'">{{ $t("message.header.help") }}</a>
          <router-link :to="{ name: 'about',query:{id: $route.query.id}}" v-else>{{ $t("message.header.help") }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reportWebVitals } from "@/utils/ga.js";
export default {
  name: "Header",
  data() {
    return {
      curIndex: 1,
      search: "",
    };
  },
  methods: {
    handleGaclick(type) {
      reportWebVitals(`${type}_click_nav`, "", "nav");
    },
  },
  mounted() {
    console.log(this.$route.name);
  },
};
</script>

<style scoped lang="scss">
a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.header_box {
  //    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  .header {
    width: 100%;
    height: 90px;
    padding-left: 20px;
    padding-right: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header_a {
      width: 180px;
    }
    .logo-img {
      width: 180px;
      height: auto;
    }
    .nav {
      margin-left: 0;
      display: flex;
      align-items: center;
      height: 100%;

      .nav_list {
        padding: 0 36px;
        font-size: 30px;
        font-weight: 400;
        height: 100%;
        position: relative;
        a {
          color: #000;
          font-size: 30px;
        }
      }
      .cur_nav_list {
        a {
          color: #ffbf00;
        }
      }
      .cur_nav_list::after {
        content: "";
        position: absolute;
        bottom: 3px;
        left: 50%;
        transform: translateX(-50%);
        background: #ffbf00;
        width: 27px;
        height: 6px;
        border-radius: 3px;
      }
    }
  }
}
@media (min-width: 760px) {
  .header_box {
         box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    .header {
      width: 1200px;
      margin: 0 auto;
      padding-left: 130px;
      padding-right: 130px;
       .header_a {
      width: 140px;
       .logo-img {
        width: 140px;
      }
    }
     
      .nav {
        .nav_list {
          a {
            font-size: 20px;
          }
        }
      }
    }
  }
}
</style>
