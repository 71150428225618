export default {
  state: {
    documentInfo: null
  },
  mutations: {
    setDocumentInfo(state, value) {
      state.documentInfo = value
    }
  },
  actions: {

  }
}