import Vue from 'vue'
import App from './App.vue'
import '@/assets/css/reset.scss'
import '@/assets/css/common.scss'

import routes from './router.js'

import VueRouter from 'vue-router'
Vue.use(VueRouter)

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

import { Loading } from 'vant';
Vue.use(Loading);

import Vuex from 'vuex'
Vue.use(Vuex)

import storeConfig from './store/index.js'


import messages from '@/language/index.js'

// eslint-disable-next-line no-useless-escape
var locale = window.location.pathname.replace(/^\/([^\/]+).*/i, '$1');
const flag = (locale.trim().length && locale != "/")

const defaultLocale = 'en'
// 通过选项创建 VueI18n 实例
const i18n = new VueI18n({
  locale: flag ? locale : defaultLocale,
  fallbackLocale: 'en',
  messages, // 设置地区信息
})

Vue.config.productionTip = false

const router = new VueRouter({
  base: flag ? ('/' + locale) : `/${defaultLocale}`,
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes
})

const store = new Vuex.Store(storeConfig)

new Vue({
  render: h => h(App),
  router,
  i18n,
  store
}).$mount('#app')
