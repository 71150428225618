
const messages = {
  index: {
    bannerText1: 'Just one click',
    bannerText2: 'Get you passport photo',
    contentTitle: 'Choose your photo type',
    contentTitle2: 'Stay back while our AI creates your photo',
    downloadText: 'Download for',
    priceOff: '50% off',
    getNow: 'Get Now!',
    desc: '100% Biometric Gurantee/Digital download - get the retouched photos immediately/Automatic Image Background correction/Get - 1 x Photo for online applications/Get - 1 x Printable Photo Sheets/Print yourself or at Walmart, CVS, Walgreeds, drug stores, etc/Use for online applications/No Ads',
    productTitle: 'Save your money & time',
    productText1: 'Upload photo',
    productText2: 'Let AI handle it',
    productText3: 'Get your photo in 11 seconds',
    downloadText2: "Try as many times as you wish It’s free until your download",
    PassportType: 'Passport type',
    BackgroundColor: 'Background color'
  },
  start: {
    uploadText: 'Upload from this phone',
    uploadTips1: 'No photos on the phone?',
    uploadTips2: 'Try taking photos with your phone',
    photoInvalidMsg: 'Photo invalid, please choose another one',
    photoInvalidMsg1: 'Error uploading file',
    photoInvalidMsg2: 'photo size should be smaller than 3000*3000px',
    againText: 'Upload again',
    download: 'Download',
    loadText: 'Photo will be auto-downloaded in a few seconds, if not, click the button above',
    successMsg: 'payment success',
    errorMsg: 'getFinalPhoto Error',
    uploadErr: 'Request failed, please try again later'
  },
  about: {
    title: 'About Us',
    contentTitle: 'Our Mission',
    contentTitle1: 'Contact Us',
    contentItem: '- Save your time and money',
    contentItem1: '- Free your hands to get a Passport/Visa/ID photos',
    contentItem2: '- Automatically generate the photo with the correct standards including the background color and the dimension',
    contentItem3: '- Give you the best experience and the lowest cost.',
    email: 'help@getpassportphoto.online',
    address: '5 Serangoon ave.2, the sunglade, Singapore 556132'
  },
  footer: {
    aboutTitle: 'ABOUT US',
    privacyTitle: 'PRIVACY POLICY'
  },
  header: {
    home: 'Home',
    start: "Start",
    help: 'Help'
  }
}
export default messages
